import Service from './service';

class UserService extends Service {
    constructor() {
        super();
        this.api_name = 'ContentManagerAPI';
        this.api_path = 'user';
    }

    getUserPlan = async (user_id) => {
        return {};
        //return this.doGetRequest('/plan');
    }

    getNotification = async () => {
        return this.doPostRequest('get-notification', {});
    }

    deleteNotification = async (notificationId) => {
        return this.doPostRequest('delete-notification', {notificationId});
    }

    markNotificationAsRead = async (notificationId) => {
        return this.doPostRequest('read-notification', {notificationId});
    }

    checkIfHasNewNotification = async () => {
        return this.doPostRequest('check-new-notification', {});
    }

    retryNotification = async (notificationId) => {
        return this.doPostRequest('retry-notification', {notificationId});
    }

    updateUser = async (data) => {
        return this.doPostRequest('update-account', data);
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new UserService();
