import React, { useState, useEffect } from 'react';
import { FaBell } from 'react-icons/fa';
import { BsBellSlash, BsThreeDots } from 'react-icons/bs';
import { Dropdown, ButtonGroup, Spinner, Row, Col, Container } from 'react-bootstrap';
import UserService from "../../../services/user";
import './UserNotificationManager.scss';

const UserNotificationManager = ({ hasNewNotification }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [expandedTextId, setExpandedTextId] = useState(null);
  const [notificationsToRemove, setNotificationsToRemove] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);
  const [hasNew, setHasNew] = useState(false);

  useEffect(() => {
    if (showNotifications) {
      loadNotification();
    }
  }, [showNotifications]);

  useEffect(() => {
    setHasNew(hasNewNotification);
  }, [hasNewNotification]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const allRead = notifications.length > 0 && notifications.every(notification => notification.readed);
    if (allRead || notifications.length === 0) {
      setHasNew(false);
    } else {
      setHasNew(true);
    }
  }, [notifications]);

  const loadNotification = async () => {
    try {
      const result = await UserService.getNotification();
      setNotifications(result.data);
      setNotificationsLoaded(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleSubmenuAction = async (action, notificationId) => {
    if (action === 'delete') {
      try {
        setNotificationsToRemove((prev) => [...prev, notificationId]);

        setTimeout(async () => {
          const result = await UserService.deleteNotification(notificationId);
          if (result) {
            setNotifications((prev) => prev.filter((item) => item.id !== notificationId));
          }
        }, 500);
      } catch (error) {
        console.log(error);
      }
    } else if (action === 'readed') {
      try {
        const result = await UserService.markNotificationAsRead(notificationId);

        if (result) {
          setNotifications((prevNotifications) =>
            prevNotifications.map((item) =>
              item.id === notificationId ? { ...item, readed: true } : item
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else if (action === 'retry') {
      try {
        const result = await UserService.retryNotification(notificationId);

        if (result) {
          setNotifications((prevNotifications) =>
            prevNotifications.map((item) =>
              item.id === notificationId ? { ...item, readed: true } : item
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleTextClick = async (notificationId) => {
    if (!notifications.find(notification => notification.id === notificationId).readed) {
      try {
        await handleSubmenuAction('readed', notificationId); 
      } catch (error) {
        console.log(error);
      }
    }
    setExpandedTextId((prevId) => (prevId === notificationId ? null : notificationId));
  };

  const NoNotificationsMessage = () => (
    <Container>
      <Row className="no-notifications-message align-items-center justify-content-center mt-4">
        <Col className="text-center">
          <BsBellSlash size={30} style={{ marginBottom: '10px', color: '#888' }} />
          <p style={{ fontSize: '15px', color: '#555' }}>
            nenhuma notificação no momento
          </p>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div className="user-notification-manager">
      <ButtonGroup>
        <Dropdown show={showNotifications}>
          <Dropdown.Toggle variant="link" id="notifications-dropdown" onClick={handleToggleNotifications}>
            <div className="bell-icon-container">
              <FaBell size={24} />
              {hasNew === true && <div className="red-dot" />}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="scrollable">
            {loading ? (
              <div className="text-center mt-3">
                <Spinner className='mt-4' animation="border" variant="primary" />
              </div>
            ) : (
              <div className="notifications-container">
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <Dropdown.Item
                      key={notification.id}
                      className={`notification-item d-flex align-items-start ${
                        notification.readed ? 'readed' : 'unread'
                      } ${notificationsToRemove.includes(notification.id) ? 'remove-animation' : ''}`}
                    >
                      {notification.readed === false && <div className="unread-indicator" />}

                      <div className="notification-content d-flex">
                        <img
                          src={notification.public_cover_url === false ? '../../../../assets/img/seasons.svg' : notification.public_cover_url}
                          alt={notification.title}
                          width="60px"
                          height="60px"
                          className="notification-image rounded mr-3"
                        />
                        <div className="notification-text flex-grow-1">
                          <strong className="mb-1">{notification.title}</strong>
                          <p
                            className={`mb-0 ${
                              expandedTextId === notification.id ? 'expanded-text' : ''
                            }`}
                            onClick={() => handleTextClick(notification.id)}
                            style={{ wordBreak: 'break-word', maxWidth: '100%', whiteSpace: 'pre-wrap' }}
                          >
                            {expandedTextId === notification.id
                              ? notification.description
                              : notification.description.length > 100
                              ? `${notification.description.slice(0, 100)}...`
                              : notification.description}
                          </p>
                        </div>

                        {/** submenu */}
                        <div className="ellipsis-button-container">
                          <span style={{marginTop: "10px", fontFamily: 'Roboto', color: "#808080"}}>{notification.eventDate.label}</span>
                          <Dropdown as={ButtonGroup} drop="right">
                            <Dropdown.Toggle
                              variant="link"
                              id={`submenu-${notification.id}`}
                              className="ellipsis-button"
                              style={{right: "8px"}}
                            >
                              <BsThreeDots />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  if (!notification.readed) {
                                    handleSubmenuAction('readed', notification.id);
                                  }
                                }}
                                disabled={notification.readed}
                              >
                                Marcar como Lido
                              </Dropdown.Item>

                              <Dropdown.Item
                                onClick={() => {
                                  handleSubmenuAction('delete', notification.id);
                                }}
                              >
                                Excluir
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => {handleSubmenuAction('retry', notification.id)}} disabled={notification.canRetry === 'no' ? true : false}>
                                Reprocessar
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                      </div>
                    </Dropdown.Item>
                  ))
                ) : (
                  <NoNotificationsMessage />
                )}
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
    </div>
  );
};

export default UserNotificationManager;
