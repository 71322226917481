import React, { useEffect } from "react";

import UbookStudioHeader from "./UbookStudioHeader";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { withRouter } from 'app/hooks/withRouter';

import { setLeftbarSetFixed } from 'app/store/slices/dashboard';
import PodcastSidebar from "app/views/component/PodcastSidebar/PodcastSidebar";
import { NotificationContainer } from 'react-notifications';

const UbookStudio = () => {
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);
    const session = useSelector((state) => state.session);

    const updateDimensions = () => {
        if (window.innerWidth < 1120) {
            dispatch(setLeftbarSetFixed(false));
        } else {
            dispatch(setLeftbarSetFixed(true));
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);  
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            { session.loggedIn && <UbookStudioHeader /> }

            <div className={`app-admin-wrap layout-sidebar-large`}>
                { session.loggedIn && dashboard.currentProduct.data && ((dashboard.leftSidebar.open || dashboard.leftSidebar.fixed) && !dashboard.leftSidebar.hidden ) && (
                    <PodcastSidebar />
                ) }
                <div
                    className={`${session.loggedIn ? "main-content-wrap d-flex flex-column": ""} ${(dashboard.currentProduct.data && dashboard.leftSidebar.fixed && !dashboard.leftSidebar.hidden) ? "podcast-sidenav-open": ""}`}
                >
                    <Outlet />
                </div>
            </div>
            { <NotificationContainer /> }
            {/* <WaitRequest show={this.state.showLoader}></WaitRequest> */}
        </div>
    );
}

export default withRouter(UbookStudio);
