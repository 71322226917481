import React, { useState, useEffect } from "react";
import "./podcastSidebar.scss";

import ProductService from "app/services/product";
import ChapterService from "app/services/chapter";
import RssService from "../../../services/rss";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from 'app/hooks/withRouter';

import RestrictPodcastModalMessage from "../Modal/PodcastPublishRestrictContentDialogComponent";
import ProductWarningWithoutMonetization from "../Modal/ProductWarningWithoutMonetization";
import PodcastFirstPublishModalMessage from "../../component/Modal/PodcastFirstPublishDialog";
import ChapterPublishRestrictionMessage from "../../component/Modal/ChapterPublishRestriction";

import { loadCurrentProduct } from '../../../store/slices/dashboard';

import { NavLink, useNavigate } from "react-router-dom";
import Util from "app/services/utils";

const PodcastSidebar = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.dashboard);

    const [coverImage, setCoverImage] = useState(null);
    const [showRestrictModal, setShowRestrictModal] = useState(false);
    const [showProductWihtoutMonetizationModal, setShowProductWihtoutMonetizationModal] = useState(false);
    const [showReservedModal, setShowReservedModal] = useState(false);
    const [showChapterPublishRestrictionModal, setShowChapterPublishRestrictionModal] = useState(false);
    const [podcastHasPlan, setPodcastHasPlan] = useState(false);
    const [unlockChaptersTrigger, setUnlockChaptersTrigger] = useState(false);
    const [publishRequestMessage, setPublishRequestMessage] = useState("");


    useEffect(() => {
        if (dashboard.currentProduct.data) {
            if (dashboard.currentProduct.data.type !== 'rss') {
                if (dashboard.currentProduct.data.is_ubook_reference === 'yes') {
                    setCoverImage(dashboard.currentProduct.data.cover_public_url);
                } else {
                    setCoverImage(Util.buildCoverUrl(dashboard.currentProduct.data.id, dashboard.currentProduct.data.cover_image_path, dashboard.currentProduct.data.type));
                }
            } else if (dashboard.currentProduct.data.type === 'rss') {
                RssService.getFeedCoverImgUrl(dashboard.currentProduct.data.id).then((result) => {
                    if (result.success) {
                        setCoverImage(result.message);
                    }
                }).catch((error) => {
                    console.log('setting default cover image');
                    setCoverImage(RssService.defaultRssImageError());
                });
            }
        }
    }, [dashboard.currentProduct])

    const checkMenuActive = (path, exact = false) => {
        let className = "";
        if (exact) {
            if (props.location.pathname === path) {
                className = " active";
            }
        } else {
            if (props.location.pathname.indexOf(path) !== -1) {
                className = " active";
            }
        }
        return className;
    }

    const publishChanges = async () => {
        let product_id = dashboard.currentProduct.data.id;
        let productType = dashboard.currentProduct.data.type;
        const hasPlan = await ProductService.hasPlan(product_id);

        if (!ProductService.isEbook(productType) && !await ProductService.hasChapter(product_id)) {
            setShowChapterPublishRestrictionModal(true);
            return;
        } else {
            setShowChapterPublishRestrictionModal(false);
        }

        if (!showProductWihtoutMonetizationModal && dashboard.currentProduct.data.business_model_pool === 'no' && ProductService.isEbook(dashboard.currentProduct.data.type) && !hasPlan) {
            setShowProductWihtoutMonetizationModal(true);
        } else {
            if ((!showRestrictModal && !unlockChaptersTrigger) && (dashboard.currentProduct.data.business_model_pool === 'no' && await ChapterService.hasRestrictChapter(product_id) && !hasPlan)) {
                setShowRestrictModal(true);
                return;
            } else {
                if (showRestrictModal) {
                    setUnlockChaptersTrigger(true);
                    setShowRestrictModal(false);
                }
            }
        }

        if (showReservedModal === false && dashboard.currentProduct.data.lifecycle === "reserved") {
            setPodcastHasPlan(await ProductService.hasPlan(product_id));
            setShowReservedModal(true);
            return;
        }
        await ProductService.publish(product_id, unlockChaptersTrigger).then(
            response => {
                if (response.success) {

                    console.log('reload', response);
                    setShowReservedModal(false);
                    dispatch(loadCurrentProduct(product_id));

                } else {

                    if (response.message === 'no_changes_detected') {
                        console.log('no changes detected', response);
                        dispatch(loadCurrentProduct(product_id));
                    } else {
                        console.log('fail to publish', response);
                        setPublishRequestMessage('Houve um problema ao tentar publicar o podcast.');
                    }

                }
            }
        ).catch(
            response => {
                setPublishRequestMessage('[TIMEDOUT] Houve um problema ao tentar publicar o podcast.');
            }
        );
        return;
    }

    const getProductEditorPath = () => {
        const currentProductType = dashboard.currentProduct.data.type;
        let routePath = '';

        switch (currentProductType) {
            case 'rss':
                routePath = `/product/${dashboard.currentProduct.data.id}/edit-rss`;
                break;

            case 'book':
            case 'magazine':
                routePath = `/product/${dashboard.currentProduct.data.id}/edit-audiobook`;
                break;

            case 'ebook':
            case 'ebook-magazine':
                routePath = `/product/${dashboard.currentProduct.data.id}/edit-ebook`;
                break;  
                
            case 'ebook-newspaper':
                routePath = `/product/${dashboard.currentProduct.data.id}/edit-newspaper`;
                break;

            default:
                routePath = `/product/${dashboard.currentProduct.data.id}/edit`;
                break;
        }
        return routePath;
    }

    return (
        <div className="podcast-sidebar-component">
            {dashboard.currentProduct && (
                <>
                    <ProductWarningWithoutMonetization 
                        show={showRestrictModal}
                        onClose={() => setShowProductWihtoutMonetizationModal(false)}
                    />  

                    <RestrictPodcastModalMessage 
                        show={showRestrictModal}
                        onClose={() => setShowRestrictModal(false)} 
                        onPublish={publishChanges} 
                    />

                    {
                        (!ProductService.isEbook(dashboard.currentProduct.data?.type)) &&
                        <ChapterPublishRestrictionMessage 
                        show={showChapterPublishRestrictionModal}
                        onClose={() => setShowChapterPublishRestrictionModal(false)}
                        />
                    }
                    
                    <PodcastFirstPublishModalMessage 
                        product_id={dashboard.currentProduct.data.id} 
                        type={dashboard.currentProduct.data.type} 
                        product_cover_image_url={dashboard.currentProduct.data.cover_image_path} 
                        is_pool={dashboard.currentProduct.data.business_model_pool === 'yes' ? true : false} 
                        has_plan={podcastHasPlan}
                        is_free={dashboard.currentProduct.data.business_model_pool === 'yes' ? false : !podcastHasPlan}
                        show={showReservedModal} onClose={() => setShowReservedModal(false)} 
                        onPublish={publishChanges}
                        PublishRequestMessage={publishRequestMessage}
                    />
                </>
            )}

            {dashboard.currentProduct && (
                <>
                    <div className="podcast-info">
                    <div className="cover-wrapper" onClick={() => navigate(getProductEditorPath())}>
                            {coverImage != null && (
                                <>
                                    <img className="cover" src={coverImage} alt={dashboard.currentProduct.data.title}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = RssService.defaultRssImageError();
                                        }}
                                    />
                                    <div className="edit">
                                        <img className="editImage" src='../../../../assets/img/white-edit.svg' alt="Editar Podcast" />
                                    </div>
                                </>
                            )}
                        </div>
                        
                        <div className="title">{dashboard.currentProduct.data.title}</div>
                        {(ProductService.WasPublished(dashboard.currentProduct.data) 
                            && dashboard.currentProduct.data.lifecycle !== ('publishing' || 'publish_request')) && (
                        <div style={{textAlign: 'center'}}>
                            <a href={`https://www.ubook.com/${ProductService.getRouteByProductType(dashboard.currentProduct?.data.type)}/${dashboard.currentProduct.data.ubook_catalog_item_id}`} target="_blank" rel="noopener noreferrer">Abrir na Ubook</a>
                        </div>
                        )}
                    </div>

                    <ul className="menu">
                        <li className={`panel` + checkMenuActive(`/product/${dashboard.currentProduct.data.id}`, true)}>
                            <NavLink
                                to={`/product/${dashboard.currentProduct.data.id}`}
                            >
                                <span></span>Painel
                            </NavLink>
                        </li>

                        {(dashboard.currentProduct.data.type !== 'rss' && !ProductService.isEbook(dashboard.currentProduct.data.type) && dashboard.currentProduct.data.is_ubook_reference === 'no') && (
                            <>
                                <li className={`episodes` + checkMenuActive(`/product/${dashboard.currentProduct.data.id}/episodes`)}>
                                    <NavLink
                                        to={`/product/${dashboard.currentProduct.data.id}/episodes`}
                                    >
                                        <span></span>{ ProductService.ChapterTitleByType(dashboard.currentProduct.data.type) }
                                    </NavLink>
                                </li>

                                <li className={`season` + checkMenuActive(`/product/${dashboard.currentProduct.data.id}/seasons`)}>
                                    <NavLink
                                        to={`/product/${dashboard.currentProduct.data.id}/seasons`}
                                    >
                                        <span></span>Temporadas
                                    </NavLink>
                                </li>
                            </>
                        )}

                        {(dashboard.currentProduct.data.is_ubook_reference === 'no') &&
                            <>
                                <li className={`edit` + checkMenuActive(`/product/${dashboard.currentProduct.data.id}/edit`)}>
                                    <NavLink
                                        to={getProductEditorPath()}
                                    >
                                        <span></span>Editar
                                    </NavLink>
                                </li>
                            </>
                        }

                        <li className={`plans ` + checkMenuActive(`/product/${dashboard.currentProduct.data.id}/plans`)}>
                            <NavLink
                                to={`/product/${dashboard.currentProduct.data.id}/plans`}
                            >
                                <span></span>Planos
                            </NavLink>
                        </li>

                        <li className={`reports` + checkMenuActive(`/product/${dashboard.currentProduct.data.id}/reports`)}>
                            <NavLink
                                to={`/product/${dashboard.currentProduct.data.id}/reports`}
                            >
                                <span></span>Relatórios
                            </NavLink>
                        </li>

                        {(dashboard.currentProduct.data.type !== 'rss' && !ProductService.isEbook(dashboard.currentProduct.data.type) && dashboard.currentProduct.data.is_ubook_reference === 'no') && (
                            <li className={`rss` +checkMenuActive(`/product/${dashboard.currentProduct.data.id}/rss`)}>
                                <NavLink
                                    to={`/product/${dashboard.currentProduct.data.id}/rss`}
                                >
                                    <span></span>RSS
                                </NavLink>
                            </li>
                        )}

                        {(dashboard.currentProduct.data.status === 'active' && ((dashboard.currentProduct.data.lifecycle === 'published' || dashboard.currentProduct.data.lifecycle === 'update_error')) && 
                            <li className={`preferences` + checkMenuActive("/preferences")}>
                                <NavLink
                                    to={`/product/preferences/${dashboard.currentProduct.data.id}`}
                                    state={{ id: dashboard.currentProduct.data.id }}
                                >
                                    <span></span>Preferências
                                </NavLink>
                            </li> 
                        )}
                    </ul>
                </>
            )}
        </div>
    )
}

export default withRouter(PodcastSidebar);