import React from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { WaitRequest } from "app/views/component/waitRequest/waitRequest";
import { updateAccount } from 'app/store/slices/session';
import { Navigate } from "react-router-dom";

import "./style.scss"

const UserDataSchema = yup.object().shape({
    name: yup.string().required("Informe seu nome completo."),
    document: yup.string().matches(/^[0-9]+$/, "Insira apenas números").min(11, "Precisa ser um CPF ou CNPJ válido").max(14, "Precisa ser um CPF ou CNPJ válido").required("Informe um CPF ou CNPJ"),
    document_type: yup.boolean()
});


const FillUserData = () => {
    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const initialValues = {
        name: session.credentials.user_account?.account?.name === null ? "" : session.credentials.user_account.account.name,
        document_type: false,
        document: session.credentials.user_account?.account?.document === null ? "" : session.credentials.user_account.account.document,
    }

    const handleSubmit = async (values) => {
        dispatch(updateAccount({
            ...values,
            id: session.credentials.user.id,
            account_id: session.credentials.user_account?.account.id,
            document_type: values.document_type ? 'cnpj' : 'cpf'
        }));
    };

    if (!session.shouldUpdateData && session.loggedIn) {
        return (<Navigate to='/' />);
    }

    return (
        <div
            className="auth-layout-wrap"
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ubook Studio</title>
            </Helmet>
            <div className="auth-content">
                <div className="card o-hidden">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="p-4">
                                <div className="auth-logo text-center mb-4">
                                    <img src="/assets/images/logo-studio.svg" alt="" />
                                </div>

                                <h2 className="text-center mb-5">Atualize seu cadastro</h2>

                                {session.register.failed && (
                                    <span className="LoginFail">{session.register.error_message}</span>
                                )}
                                
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={UserDataSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="fullname">Nome Completo</label>
                                                <input
                                                    className="form-control dark"
                                                    name="name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                />
                                                {errors.name && touched.name && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                            

                                            <div className="form-group">
                                                    <label htmlFor="document">{true ? "CPF" : "CNPJ"}</label>
                                                    <input
                                                        name="document"
                                                        className="form-control dark"
                                                        type="text"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.document}
                                                    />
                                                    {errors.document && touched.document && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.document}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-md-12 form-group mb-3">
                                                <Form.Check
                                                    inline
                                                    name="document_type"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="switch"
                                                    label="Utilizar CNPJ."
                                                    className="accept_terms"
                                                /> 
                                                    {errors.document_type && touched.document_type && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.document_type}
                                                        </div>
                                                    )}
                                            </div>
                                           
                                            <button
                                                className="btn btn-primary w-100 my-1 btn-rounded mt-3"
                                                type="submit"
                                            >
                                                Atualizar Conta
                                            </button>

                                            {/* <span className="create-account">
                                                <Link to="/signin">Voltar ao Login</Link>
                                            </span> */}
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <WaitRequest show={session.updateAccount.processing}></WaitRequest>
        </div>
    );
}

export default FillUserData;
