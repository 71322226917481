import React from "react";
import { Formik } from "formik";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { login } from 'app/store/slices/session';

import recaptcha from "@recaptcha";

import {WaitRequest} from "app/views/component/waitRequest/waitRequest";
import {Helmet} from "react-helmet";

import { Navigate, Link } from "react-router-dom";

// import {
//     NotificationContainer,
//     NotificationManager,
// } from "react-notifications";

import "./style.scss";

const SigninSchema = yup.object().shape({
    email: yup.string().email("O e-mail é inválido.").required("O e-mail é obrigatório."),
    password: yup
        .string()
        .required("A senha é obrigatória"),
});
const initialValues = {
    email: "",
    password: ""
};

const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);
    if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function () {
            if (callback) callback()
        };
        document.body.appendChild(script)
    }
    if (isScriptExist && callback)
        callback();
}

const Signin = () => {
    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const handleSubmit = async (value) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(recaptcha.captcha_v3_public_key, { action: 'signin' }).then(captcha_token => {
                dispatch(login({
                    'email': value['email'], 
                    'password': value['password'], 
                    captcha_token
                }));
           });
       });
    };

    const forgotPassword = () => {
        window.open('https://www.ubook.com/forgotPassword', '_blank');
    };

    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${recaptcha.captcha_v3_public_key}`);

    if (session.shouldUpdateData && session.loggedIn) {
        return (<Navigate to='/complete-document' />);
    } else {
        if (session.loggedIn) {
            return (<Navigate to='/' />);
        }
    }


    return (
        <div
            className="auth-layout-wrap"
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ubook Studio</title>
            </Helmet>

            <div className="auth-content">
                <div className="auth-logo text-center mx-auto">
                    <img src="/assets/images/logo-studio.svg" alt="" />
                </div>
                <div className="card o-hidden">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-4">

                                {session.login.failed && (
                                    <span className="LoginFail">{session.login.error_message}</span>
                                )}
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={SigninSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email">E-mail</label>
                                                <input
                                                    className="form-control position-relative dark"
                                                    type="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    autoComplete="off"
                                                />
                                                {errors.email && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.email}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Senha</label>
                                                <input
                                                    className="form-control dark"
                                                    type="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    autoComplete="off"
                                                />
                                                {errors.password && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.password}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center my-3">
                                                <label style={{ flex: 1 }}>
                                                    <input type="checkbox" /> Lembrar senha
                                                </label>
                                                <button
                                                    className="btn btn-rounded btn-primary my-1 mt-2 ml-auto"
                                                    type="submit"
                                                    style={{ flex: 1 }}
                                                >
                                                    Entrar
                                                </button>
                                            </div>

                                            <span className="create-account">
                                                Não tem uma conta? <Link to="/signup">Cadastre-se agora!</Link>
                                            </span>
                                        </form>
                                    )}
                                </Formik>

                                <div className="mt-3 text-center">
                                    <span onClick={forgotPassword} className="forgot-pass text-muted">
                                        <span className="link">Esqueceu a senha?</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <WaitRequest show={session.login.processing}></WaitRequest>
            {/* <NotificationContainer /> */}
        </div>
    );
};

export default Signin;
