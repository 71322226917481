import React, { useEffect } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import recaptcha from "@recaptcha";
import { Helmet } from "react-helmet";

import utils from "app/services/utils";
import { WaitRequest } from "app/views/component/waitRequest/waitRequest";
import { register } from 'app/store/slices/session';
import { Link, Navigate } from "react-router-dom";

import "./style.scss"

const SignupSchema = yup.object().shape({
    fullname: yup.string().required("Informe seu nome completo."),
    email: yup.string().email("E-mail inválido.").required("E-mail é obrigatório."),
    accept_terms:  yup.boolean().required("Você precisa aceitar os termos de uso da plataforma para prosseguir.")
    .oneOf([true], "Você precisa aceitar os termos de uso da plataforma para prosseguir."),
    password: yup
        .string()
        .min(8, "A senha deve ter no minimo 8 caracteres.")
        .required("É necessário informar uma senha."),
    repassword: yup
        .string()
        .required("Confirme a senha acima.")
        .oneOf([yup.ref("password")], "As senhas não conferem."),
        document: yup.string().matches(/^[0-9]+$/, "Insira apenas números").min(11, "Precisa ser um CPF ou CNPJ válido").max(14, "Precisa ser um CPF ou CNPJ válido").required("Informe um CPF ou CNPJ"),
        document_type: yup.boolean()
});

const initialValues = {
    email: "",
    fullname: "",
    password: "",
    repassword: "",
    accept_terms: false,
    document_type: false,
    document: "",
    post_permission: 1
}

const Signup = () => {
    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    useEffect(() => {
        utils.loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${recaptcha.captcha_v3_public_key}`);
    }, []);

    const handleSubmit = async (values) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(recaptcha.captcha_v3_public_key, { action: 'signup' }).then(captcha_token => {
                values['captcha_token'] = captcha_token;
                dispatch(register(values));
            });
        });
    };

    if (session.loggedIn)
        return (<Navigate to='/' />);

    return (
        <div
            className="auth-layout-wrap"
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ubook Studio</title>
            </Helmet>
            <div className="auth-content">
                <div className="card o-hidden">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="p-4">
                                <div className="auth-logo text-center mb-4">
                                    <img src="/assets/images/logo-studio.svg" alt="" />
                                </div>

                                {session.register.failed && (
                                    <span className="LoginFail">{session.register.error_message}</span>
                                )}
                                
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={SignupSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="fullname">Nome Completo</label>
                                                <input
                                                    className="form-control dark"
                                                    name="fullname"
                                                    type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.fullname}
                                                />
                                                {errors.fullname && touched.fullname && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.fullname}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">E-mail</label>
                                                <input
                                                    name="email"
                                                    className="form-control dark"
                                                    type="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="text-danger mt-1 ml-2">
                                                        {errors.email}
                                                    </div>
                                                )}
                                            </div>
                                            
                                                <div className="form-group">
                                                    <label htmlFor="password">Senha</label>
                                                    <input
                                                        name="password"
                                                        className="form-control dark"
                                                        type="password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password}
                                                    />
                                                    {errors.password && touched.password && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.password}
                                                        </div>
                                                    )}
                                                </div>
                                           
                                                <div className="form-group">
                                                    <label htmlFor="repassword">Confirmar Senha</label>
                                                    <input
                                                        name="repassword"
                                                        className="form-control dark"
                                                        type="password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.repassword}
                                                    />
                                                    {errors.repassword && touched.repassword && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.repassword}
                                                        </div>
                                                    )}
                                                </div>

                                            

                                            <div className="form-group">
                                                    <label htmlFor="document">{true ? "CPF" : "CNPJ"}</label>
                                                    <input
                                                        name="document"
                                                        className="form-control dark"
                                                        type="text"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.document}
                                                    />
                                                    {errors.document && touched.document && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.document}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-md-12 form-group mb-3">
                                                <Form.Check
                                                    inline
                                                    name="document_type"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="switch"
                                                    label="Utilizar CNPJ."
                                                    className="accept_terms"
                                                /> 
                                                    {errors.document_type && touched.document_type && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.document_type}
                                                        </div>
                                                    )}
                                            </div>
                                           
                                            <div className="col-md-12 form-group mb-3">
                                                <Form.Check
                                                    inline
                                                    name="accept_terms"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="switch"
                                                    label="Li e aceito os "
                                                    className="accept_terms"
                                                /> <span onClick={()=> window.open("https://studio.ubook.com/termosdeuso", '_blank').focus()} className="use-terms">termos de uso da plataforma.</span>

                                                    {errors.accept_terms && touched.accept_terms && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {errors.accept_terms}
                                                        </div>
                                                    )}
                                            </div>

                                            <button
                                                className="btn btn-primary w-100 my-1 btn-rounded mt-3"
                                                type="submit"
                                            >
                                                Criar Conta
                                            </button>

                                            <span className="create-account">
                                                <Link to="/signin">Voltar ao Login</Link>
                                            </span>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <WaitRequest show={session.register.processing}></WaitRequest>
        </div>
    );
}

export default Signup;
